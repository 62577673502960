
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { Brand } from '@/store/models/ItemModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class EditBrandDialog extends Vue {
  $refs!: {
    fileUpload: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  brandToEdit: Brand = new Brand();
  shouldShowNewBrand: boolean = false;
  isFormValid: boolean = false;
  isBringingUpFileDisplay: boolean = false;
  hasUploadBrandLogoFailed: boolean = false;
  isUploadingBrandLogo: boolean = false;
  brandLogoSrc: string = '';
  shouldAutoRequestCodes: boolean = false;

  errorMsg: string = '';

  file: any = null;
  fileDescription: string = '';
  /* Async Functions */
  /* Utility Functions */
  @Watch('file')
  async onFileUpload(val: any) {
    this.hasUploadBrandLogoFailed = false;
    if (val && val.name) {
      this.file = val;
      if (!this.fileDescription) this.fileDescription = this.file.name;
      this.isUploadingBrandLogo = true;
      this.brandLogoSrc = '';
      let formData = new FormData();
      formData.append('files[]', val, val.name);
      formData.append('brandId', `${this.brandToEdit.Id}`);
      this.isUploadingBrandLogo = true;
      try {
        const response = await CustomerService.UploadBrandLogo(formData);
        this.errorMsg = '';
        this.isUploadingBrandLogo = false;
        this.brandToEdit.BrandLogoFileName = response!.data.newFileName;
        this.brandToEdit.OriginalFileName = response!.data.originalFileName;
      } catch (err) {
        this.hasUploadBrandLogoFailed = true;
        this.isUploadingBrandLogo = false;
        this.brandLogoSrc = '';
        if (typeof err === 'string') {
          this.errorMsg = err.toString();
        }
        SetSnackBar('Something went wrong updating your file');
        return;
      }
      this.isUploadingBrandLogo = false;
      var reader = new FileReader();
      reader.onload = () => {
        if (reader && reader.result) {
          this.brandLogoSrc = reader.result.toString();
        }
      };
      reader.readAsDataURL(val);
    }
  }
  onFileUploadClick() {
    this.fileDescription = '';
    this.$refs.fileUpload.$refs.input.click();
  }
  addNewBrand() {
    this.shouldShowNewBrand = true;
  }
  openDialog(brandToEdit: Brand) {
    this.dialog = true;
    this.brandToEdit = brandToEdit;
  }
  closeDialog() {
    this.dialog = false;
  }
  canEditBrand() {
    if (this.brandToEdit.ShouldRequestCodes && !this.brandToEdit.BrandId) return false;
    return this.isFormValid;
  }
  /* Loaders */
  async updateBrand() {
    this.isLoading = true;
    try {
      await CustomerService.UpdateBrand(this.brandToEdit);
      this.$emit('refreshCustomer');
      this.brandToEdit = new Brand();
      this.brandLogoSrc = '';
      this.closeDialog();
      SetSnackBar('Updated brand successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error updating your brand. Please contact techsupport@just1label for help.'
      );
    }
    this.isLoading = false;
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
