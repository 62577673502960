
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ItemService from '@/services/item-service';
import { Brand, Item } from '@/store/models/ItemModel';
import EditBrandDialog from '@/components/BrandEditDialog.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: {
    EditBrandDialog
  }
})
export default class PreviewFnskuComboDialog extends Vue {
  $refs!: {
    EditBrandDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) brand!: Brand;
  /* Store Actions */
  /* Watchers */
  /* Data */
  isGeneratingPreviewGraphic: boolean = false;
  isPrevewGraphicReadyToDisplay: boolean = true;
  shouldShowNeedsBrandAlert: boolean = false;
  dialog: boolean = false;
  previewGraphic1UpFileName: string = '';
  generated1UpSrc: string = '';
  isType4: boolean = false;
  /* Async Functions */
  /* Utility Functions */
  openDialog(isType4: boolean) {
    this.resetData();
    this.isType4 = isType4;
    this.dialog = true;
    if (!isType4) return;
    if (!this.brand.HasBrandLogo) {
      this.shouldShowNeedsBrandAlert = true;
    }
    this.generate1Up(this.brand.Id);
  }
  closeDialog() {
    this.dialog = false;
  }
  openBrandEditDialog() {
    this.$refs.EditBrandDialog.openDialog(this.brand);
  }
  async getPreviewGraphic() {
    try {
      // this.generated1UpSrc = `/Item/GetGenerated1Up?fileName=${this.previewGraphic1UpFileName}`;
      const { data } = await ItemService.GetGenerated1Up(this.previewGraphic1UpFileName);
      this.generated1UpSrc = data;
      this.isGeneratingPreviewGraphic = false;
      this.isPrevewGraphicReadyToDisplay = true;
    } catch (error) {
      this.handleError();
    }
  }
  async generate1Up(brandId: number) {
    try {
      const { data } = await ItemService.DispatchGenerate1Up(
        '5adab0e3-604f-4421-8985-c1ad72f386d4',
        brandId
      );
      if (data) {
        this.previewGraphic1UpFileName = data;
        this.isGeneratingPreviewGraphic = true;
        this.isPrevewGraphicReadyToDisplay = false;
        this.hasBrandLogoBeenProcessed();
      }
    } catch (error) {
      this.handleError();
    }
  }
  async hasBrandLogoBeenProcessed() {
    try {
      const { data } = await ItemService.HasBrandLogoBeenProcessed(
        this.brand.Id,
        this.previewGraphic1UpFileName
      );
      if (data) {
        setTimeout(() => {
          this.getPreviewGraphic();
        }, 5000);
      } else {
        setTimeout(() => {
          this.hasBrandLogoBeenProcessed();
        }, 5000);
      }
    } catch (error) {
      this.handleError();
    }
  }
  handleError() {
    this.isGeneratingPreviewGraphic = false;
    this.generated1UpSrc = '';
    this.isPrevewGraphicReadyToDisplay = false;
    SetSnackBar(
      'There was an issue generating your preview graphic. Please contact techsupport@just1label.com to get your issue resolved'
    );
  }
  resetData() {
    this.isGeneratingPreviewGraphic = false;
    this.isPrevewGraphicReadyToDisplay = false;
    this.generated1UpSrc = '';
    this.previewGraphic1UpFileName = '';
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
