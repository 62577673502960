
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { Brand } from '@/store/models/ItemModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class BrandAddDialog extends Vue {
  $refs!: {
    fileUpload: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  newBrand: Brand = new Brand();
  isFormValid: boolean = false;
  isBringingUpFileDisplay: boolean = false;
  hasUploadBrandLogoFailed: boolean = false;
  isUploadingBrandLogo: boolean = false;
  brandLogoSrc: string = '';

  errorMsg: string = '';

  file: any = null;
  fileDescription: string = '';
  /* Async Functions */
  /* Utility Functions */
  @Watch('file')
  async onFileUpload(val: any) {
    this.hasUploadBrandLogoFailed = false;
    if (val && val.name) {
      this.file = val;
      if (!this.fileDescription) this.fileDescription = this.file.name;
      this.isUploadingBrandLogo = true;
      this.brandLogoSrc = '';
      let formData = new FormData();
      formData.append('files[]', val, val.name);
      this.isUploadingBrandLogo = true;
      try {
        const response = await CustomerService.UploadBrandLogo(formData);
        this.errorMsg = '';
        this.isUploadingBrandLogo = false;
        this.newBrand.BrandLogoFileName = response!.data.newFileName;
        this.newBrand.OriginalFileName = response!.data.originalFileName;
      } catch (err) {
        this.hasUploadBrandLogoFailed = true;
        this.isUploadingBrandLogo = false;
        this.brandLogoSrc = '';
        if (typeof err === 'string') {
          this.errorMsg = err.toString();
        }
        SetSnackBar('Something went wrong uploading your brand');
        return;
      }
      this.isUploadingBrandLogo = false;
      var reader = new FileReader();
      reader.onload = e => {
        if (reader && reader.result) {
          this.brandLogoSrc = reader.result.toString();
        }
      };
      reader.readAsDataURL(val);
    }
  }
  onFileUploadClick() {
    this.fileDescription = '';
    this.$refs.fileUpload.$refs.input.click();
  }
  async addNewBrand() {
    try {
      await CustomerService.AddNewBrand(this.newBrand);
      this.$emit('refreshCustomer', this.newBrand);
      this.newBrand = new Brand();
      this.brandLogoSrc = '';
      SetSnackBar('Added brand successfully');
      this.closeDialog();
    } catch (err) {
      SetSnackBar(
        'There was an error adding your brand. Please contact techsupport@just1label.com'
      );
    }
  }
  openDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  canAddBrand() {
    if (this.newBrand.ShouldRequestCodes && !this.newBrand.BrandId) return false;
    return this.isFormValid;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
